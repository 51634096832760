<template>
    <div data-cy="min-nav" class="absolute left-0 top-0 w-full py-3 z-20">
        <div class="max-w-3xl mx-auto px-4 flex items-center justify-between">
            <NavLogo data-cy="min-nav-logo" showBlack />
            <AccountOptions :currentRouteName="currentRouteName" :loggedIn="$auth.loggedIn()" />
        </div>
    </div>
</template>

<script>
import NavLogo from "./navLogo";
import AccountOptions from "./accountOptions";
export default {
    name: "MinimalNav",
    components: {
        NavLogo,
        AccountOptions,
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>
