<template>
    <div class="ml-2 relative" data-cy="acc-options">
        <popup
            buttonAria="Show / hide account options"
            buttonClasses="h-8 w-8 lg:w-10 lg:h-10 relative rounded-full"
            popupClasses="bg-background p-3 rounded-lg w-56 ml-8 border border-mid-grey text-base"
            transform="translate(-100%, 100%);"
            :closePopupCount="closePopupCount"
        >
            <template v-slot:button-content>
                <div class="flex items-center justify-center">
                    <!-- <p>{{ (showWhiteVersion && currentRouteName !== "account") || (!showWhiteVersion && currentRouteName === "account") }}</p> -->
                    <!-- {{ showWhiteVersion }} -->
                    <div
                        class="w-4 h-5 relative z-10 fill-current text-black"
                        :class="[
                            { 'text-white': !showWhiteVersion && currentRouteName === 'account' },
                            { 'text-white sm:text-primary': showWhiteVersion },
                        ]"
                    >
                        <svg class="use-svg"><use xlink:href="#person" /></svg>
                    </div>
                    <div
                        class="bg-white btn-bg flex items-center justify-center"
                        :class="{ 'opacity-0': !showWhiteVersion || currentRouteName !== 'account' }"
                    ></div>
                    <div
                        class="bg-black btn-bg flex items-center justify-center"
                        :class="{ 'opacity-0': showWhiteVersion || currentRouteName !== 'account' }"
                    ></div>
                </div>
            </template>
            <template v-slot:popup-content>
                <!-- Link to account page -->
                <router-link v-if="loggedIn" data-cy="acc-link" to="/account" class="relative p-1 w-full block" @click="closePopup">
                    {{ strings.account }}
                </router-link>
                <!-- Login or logout -->
                <button v-if="loggedIn" @click="logout" data-cy="logout-btn" class="relative p-1 w-full block text-left">{{ strings.logout }}</button>
                <router-link v-else-if="portalConfig.login_allowed" data-cy="login-btn" to="/login" class="relative p-1 w-full block text-left">{{
                    strings.login
                }}</router-link>
                <!-- Link to findhelp page (where included) -->
                <router-link v-if="portalConfig.findhelp_auntbertha_page" to="/findhelp" class="relative p-1 w-full block" @click="closePopup">{{
                    strings.findhelp
                }}</router-link>
                <!-- Access options -->
                <div
                    v-show="!hide_contrast_toggle"
                    class="flex items-center justify-between p-1"
                    :class="{ 'pt-3 mt-2 border-t border-mid-grey': portalConfig.login_allowed }"
                >
                    <p class="mr-2">{{ strings.contrast }}</p>
                    <OnOffToggle
                        id="contrast-toggle"
                        data-cy="contrast-toggle"
                        :value="highContrast"
                        description="Set high contrast mode"
                        @input="switchContrast"
                    />
                </div>
                <!-- <div class="flex items-center justify-between mt-3">
                    <p class="mr-2">{{ strings.motion }}</p>
                    <OnOffToggle
                        id="reduced-motion-toggle"
                        data-cy="reduced-motion-toggle"
                        :value="reducedMotion"
                        description="Set high contrast mode"
                        @input="switchReducedMotion"
                    />
                </div> -->
            </template>
        </popup>
    </div>
</template>

<script>
import Popup from "@/components/ui/popup";
import OnOffToggle from "@/components/ui/onOffToggle";
import { setLSItem } from "@/js/helpers/localstorage";
import { mapFields } from "vuex-map-fields";
import text from "./accountOptionsText";

export default {
    name: "AccountOptions",
    components: {
        Popup,
        OnOffToggle,
    },
    props: {
        currentRouteName: {
            type: String,
            required: true,
        },
        showWhiteVersion: Boolean,
        loggedIn: Boolean,
    },
    data() {
        return {
            closePopupCount: 0,
            strings: text,
        };
    },
    computed: {
        ...mapFields(["highContrast"]),
        hide_contrast_toggle() {
            return this.currentRouteName === "find-help";
        },
    },
    methods: {
        switchContrast(newVal) {
            setLSItem("high-contrast", newVal);
            this.highContrast = newVal;
        },
        async logout() {
            await this.$api.auth.logout();
            // window.Intercom("shutdown");
            // this.$store.commit("SET_MOBILE_MENU_HIDDEN");
            // force reload update so the logout button changes to Sign in/Register
            window.location.href = "/";
        },
        closePopup() {
            this.closePopupCount += 1;
        },
    },
};
</script>

<style scoped>
.btn-bg {
    @apply absolute left-0 top-0 w-full h-full rounded-full transition-opacity duration-300 ease-in-out;
}
</style>
