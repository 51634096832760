*/ In the privacy & terms pages, unlike the rest of the site, there's a lot of text and so I have not extracted it in the same way asother components
& pages - it seems worthwhile to have separate rules for these 2 pages in the Smartling dashboard */
<template>
    <div class="min-h-screen pt-32 max-w-xl px-4 mx-auto">
        <div id="render-element"></div>

        <PrivacyDemo v-if="portalConfig.org_name == 'Genomics England'" />
        <PrivacyFLF v-else-if="portalConfig.org_name == 'The Wellness League'" />
    </div>
</template>

<script>
// import OnOffToggle from "@/components/ui/onOffToggle";
import PrivacyDemo from "./privacy-demo.vue";
import PrivacyFLF from "./privacy-demo.vue";
// import { getLSItem, setLSItem } from "@/js/helpers/localstorage";

export default {
    name: "Privacy",
    components: {
        // OnOffToggle,
        PrivacyDemo,
        PrivacyFLF,
    },
    data() {
        return {
            services: [],
        };
    },
    mounted() {
        // this.services = this.portalConfig.privacy_services.map((priv_service) => {
        //     return { ...priv_service, active: true };
        // });
        // const ls_privacy_services = getLSItem("privacy-services");
        // // set selected value of each service pref based on local storage
        // if (ls_privacy_services) {
        //     ls_privacy_services.forEach((ls_priv_item) => {
        //         const service_found = this.services.find((x) => x.service === ls_priv_item.service);
        //         if (service_found) service_found.active = ls_priv_item.active;
        //     });
        // }
    },
    methods: {
        // updateServicePrefs() {
        //     const privacy_services = this.services.map((x) => ({ service: x.service, active: x.active }));
        //     // console.log(privacy_services);
        //     setLSItem("privacy-services", privacy_services);
        //     // If logged in then set in LS and DB otherwise LS only (for later)
        //     // if (this.$auth.loggedIn()) {
        //     //     this.$api.user.update_user_field("privacy_services", privacy_services).then(() => {
        //     //         // reload page after DB commit to refresh choices
        //     //         this.$router.go();
        //     //     });
        //     // }
        //     // reload page after DB commit to refresh choices
        //     this.$router.go();
        // },
    },
};
</script>
